export function signatureData() {
  return this.signatureData.map(sig => ({
    pngData: sig.pngData,
    name: sig.name,
    seq: sig.seq,
    completedBy: sig.completedBy,
    fields: [
      { label: 'Date:', value: sig.dateTime },
      { label: 'Relation:', value: sig.relation },
      { label: 'Type:', value: sig.type },
      { label: 'Reason:', value: sig.reason },
      { label: 'Level:', value: sig.level },
      { label: 'Role:', value: sig.role },
      { label: 'License:', value: sig.license },
      { label: 'Unable To Sign Reason:', value: sig.unableToSignReason },
      { label: 'Type Of Person:', value: sig.typeOfPerson },
    ],
  }));
}
