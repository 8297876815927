export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
};
