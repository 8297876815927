import Treatment from 'src/components/FullPatientData/PatientWidgetBody/PCRData/Treatments/Treatments';
import Vital from 'src/components/FullPatientData/PatientWidgetBody/PCRData/Vitals/Vitals';
import Assessments from 'src/components/FullPatientData/PatientWidgetBody/PCRData/Assessments/Assessments';
import Impressions from 'src/components/FullPatientData/PatientWidgetBody/PCRData/Impressions/Impressions';
import Supplies from 'src/components/FullPatientData/PatientWidgetBody/PCRData/Supplies/Supplies';
import Complaints from 'src/components/FullPatientData/PatientWidgetBody/PCRData/Complaints/Complaints';
import History from 'src/components/FullPatientData/PatientWidgetBody/PCRData/History/History';
import Addenda from 'src/components/FullPatientData/PatientWidgetBody/PCRData/Addenda/Addenda';
import Signatures from 'src/components/FullPatientData/PatientWidgetBody/PCRData/Signatures/Signatures';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { mapGetters, mapState, mapActions } from 'vuex';
import { permissionTypes } from 'src/constants/permissions';
import { widgets } from 'src/constants/widgets';
import Widget from 'src/components/FullPatientData/PatientWidgetBody/Widget/Widget';
import AccordionSlot from 'src/components/FullPatientData/PatientWidgetBody/AccordionSlot/AccordionSlot';
import DefList from 'src/components/FullPatientData/PatientWidgetBody/DefList/DefList';
import { GET_ALS_CONFIG } from 'src/store/actions/types';

// icon of the widget header
library.add(faUser);

export default {
  props: {
    loadedCallback: {
      type: Function,
      required: true,
    },
    dragStartHandler: {
      type: Function,
      required: true,
    },
  },
  components: {
    Widget,
    treatment: Treatment,
    vital: Vital,
    assessments: Assessments,
    impressions: Impressions,
    supplies: Supplies,
    complaints: Complaints,
    history: History,
    addenda: Addenda,
    signatures: Signatures,
    AccordionSlot,
    DefList,
  },
  computed: {
    ...mapGetters(['applicant', 'hasPermission']),
    ...mapState({
      visit: state => state.applicant.visits.data[0],
      vitals: state =>
        state.applicant.visits.data[0].epcrJsonData?.vitalsRecords,
      treatmentDetails: state =>
        state.applicant.visits.data[0].epcrJsonData?.treatmentDetails,
      assessmentsData: state =>
        state.applicant.visits.data[0].epcrJsonData?.tripAssessments,
      impressionsData: state =>
        state.applicant.visits.data[0].epcrJsonData?.tripImpressions,
      supplies: state =>
        state.applicant.visits.data[0].epcrJsonData?.tripSupplies,
      complaintsData: state =>
        state.applicant.visits.data[0].epcrJsonData?.complaints,
      historyData: state =>
        state.applicant.visits.data[0].epcrJsonData?.patientHistorys,
      miscData: state => state.applicant.visits.data[0].epcrJsonData?.miscData,
      addendaData: state => state.applicant.visits.data[0].data.addenda,
      tripDisclaimers: state =>
        state.applicant.visits.data[0].epcrJsonData?.tripDisclaimers,
      fdcShifts: state =>
        state.applicant.visits.data[0].epcrJsonData?.fdcShifts,
      fdcFormFieldBinary: state =>
        state.applicant.visits.data[0].epcrJsonData?.fdcTripFormFieldBinary,
      loading: state => !state.applicant.visits.retrieved,
      alsTriggers: state => state.alsConfig.triggers,
      alsColors: state => state.alsConfig.colors,
      alsConfigRetrieved: state => state.alsConfig.retrieved,
      alsConfigLoading: state => state.alsConfig.loading,
      pcsHistory: state => state.applicant.visits.data[0].data?.pcsHistory,
      profiles: state => state.profiles.data,
    }),
    // permission
    canView() {
      return this.hasPermission(permissionTypes.canViewApplicant);
    },
    canViewPCR() {
      return this.hasPermission(permissionTypes.canViewPCR);
    },
    mappedVitals: {
      get: function() {
        if (this.vitals) {
          return this.vitals.map(v => ({
            vtime: v.vtime,
            bps_bpd: this.combineBP(v.bps, v.bpd, '/'),
            pulse_pulsereg: this.combine(v.pulse, v.pulsereg, ','),
            spo2_spo2oxygensource: this.combine(
              v.spo2 + '% ',
              v.spo2oxygensource,
              ''
            ),
            temperature_100: this.calculateTemp(v.temperature),
            skincolor: v.skincolor,
            skinmoisture: v.skinmoisture,
            painscale: v.painscale,
            ecg: v.ecg,
            gcstotal: v.gcstotal,
            gcseyes: v.gcseyes,
            gcsmotor: v.gcsmotor,
            gcsverbal: v.gcsverbal,
            glucose: v.glucose,
            resprate: v.resprate,
            respeffort: v.respeffort,
            lungsoundsleft: v.lungsoundsleft,
            lungsoundsright: v.lungsoundsright,
            consciousness: v.loc,
          }));
        }
      },
    },
    mappedSignatures: {
      get: function() {
        var completedByUser = this.visit.epcrJsonData?.trip?.completedByUser;
        var arr = [];
        if (this.tripDisclaimers) {
          arr = this.tripDisclaimers.reduce(function(ids, obj) {
            var knownSignatureType = false;
            if (obj.whoname) {
              knownSignatureType = true;
              //console.log('obj.whomane', obj.whoname);
              var pngData = obj.signaturePngData
                ? 'data:image/tiff;base64,' + obj.signaturePngData
                : null;
              ids.push({
                pngData: pngData,
                type: obj.typedescr,
                dateTime: obj.tdate + ' ' + obj.timecaptured,
                name: obj.whoname,
                unableToSignReason: obj.unablereason,
                typeOfPerson: obj.typeOfPerson,
                relation: obj.whotype,
              });
            }
            if (obj.witnessName1) {
              knownSignatureType = true;
              ids.push({
                pngData: obj.witness1PngData
                  ? 'data:image/tiff;base64,' + obj.witness1PngData
                  : null,
                type: 'witness',
                name: obj.witnessName1,
              });
            }
            if (obj.witnessName2) {
              knownSignatureType = true;
              //console.log('obj.witnessName2', obj.witnessName2);
              ids.push({
                pngData: obj.witness2PngData
                  ? 'data:image/tiff;base64,' + obj.witness2PngData
                  : null,
                type: 'witness',
                name: obj.witnessName2,
              });
            }
            if (!knownSignatureType && obj.signaturePngData) {
              //catch all if they have a signature but we don't know what it is
              ids.push({
                pngData: obj.signaturePngData
                  ? 'data:image/tiff;base64,' + obj.signaturePngData
                  : null,
                type: obj.typedescr,
                dateTime: obj.tdate + ' ' + obj.timecaptured,
                typeOfPerson: obj.typeOfPerson,
              });
            }
            return ids;
          }, []);
        }

        if (this.fdcShifts) {
          arr = arr.concat(
            this.fdcShifts.map(fdcShift => ({
              pngData: fdcShift.signaturePngData
                ? 'data:image/tiff;base64,' + fdcShift.signaturePngData
                : null,
              type: this.getCrewType(fdcShift.crewNum),
              name: fdcShift.crewName,
              level: fdcShift.crewLevel,
              role: fdcShift.crewRole,
              license: fdcShift.license,
              completedBy: Number(fdcShift.employeeNumber) === completedByUser,
            }))
          );
        }
        if (this.fdcFormFieldBinary) {
          this.fdcFormFieldBinary.forEach(signatureInfo => {
            var binaryInfo = signatureInfo.binaryInfo;
            var signerName = '';
            //this is an array
            var extraInfo = signatureInfo.extraInfo;
            for (var i = 0; i < extraInfo.length; i++) {
              var fields = extraInfo[i];
              if (fields.forM_FIELD_CAPTION == 'Signer Name') {
                signerName = fields.form_field_string;
              }
            }
            arr.push({
              pngData: signatureInfo.signaturePngData
                ? 'data:image/tiff;base64,' + signatureInfo.signaturePngData
                : null,
              name: signerName,
              dateTime: binaryInfo.tdate,
            });
          });
        }
        return arr;
      },
    },
  },
  data() {
    return {
      treatments: [],
      chiefComplaints: [],
      otherComplaints: [],
      fields: [
        { key: 'timeperformed', label: 'Time' },
        { key: 'intervention_text', label: 'Treatment' },
        { key: 'who_text', label: 'Performed By' },
        { key: 'comments', label: 'Details' },
        'expand_all',
      ],
      vitalsFields: [
        { key: 'vtime', label: 'Time' },
        { key: 'bps_bpd', label: 'BP' },
        { key: 'pulse_pulsereg', label: 'Pulse' },
        { key: 'spo2_spo2oxygensource', label: 'SPO2' },
        { key: 'temperature_100', label: 'Body Temp' },
        { key: 'skincolor', label: 'Skin Color' },
        { key: 'skinmoisture', label: 'Skin Moisture' },
        { key: 'painscale', label: 'Pain' },
        { key: 'ecg', label: 'ECG' },
        'expand_all',
      ],
      assessmentsFields: [
        { key: 'category_text', label: 'Location' },
        { key: 'assessments', label: 'Assessment' },
        { key: 'who_text', label: 'Who' },
      ],
      impressionFields: [
        { key: 'descr', label: 'Description' },
        { key: 'comment', label: 'Comment' },
      ],
      supplyFields: [
        { key: 'supply', label: 'Supply Name' },
        { key: 'quantity', label: 'Quantity' },
      ],
      complaintFields: [
        { key: 'history_type', label: 'Type' },
        { key: 'long_descr', label: 'Details' },
        { key: 'duration_time_units', label: 'Duration' },
        { key: 'comment', label: 'Note' },
      ],
      historyFields: [
        { key: 'descr', label: 'Description' },
        { key: 'history_type', label: 'Type' },
        { key: 'comment', label: 'Comments' },
      ],
      miscellaneousFields: [
        { key: 'caption', label: 'Caption' },
        { key: 'descr', label: 'Description' },
      ],
      addendasFields: [
        { key: 'dateTime', label: 'Time' },
        { key: 'who', label: 'Who' },
        { key: 'description', label: 'Description' },
      ],
      alsToolTip: null,
      alsColor: null,
      alsHighestTrigger: null,
      treatmentsExpandAll: false,
      vitalsExpandAll: false,
      // newPosts: ,
    };
  },
  methods: {
    ...mapActions([GET_ALS_CONFIG]),
    widgetType() {
      return widgets.pcrData.type;
    },
    treatmentExpandAll() {
      if (this.treatments?.length) {
        this.treatmentsExpandAll = !this.treatmentsExpandAll;
        for (const item of this.treatments) {
          this.$set(item, '_showDetails', this.treatmentsExpandAll);
        }
      }
    },
    vitalExpandAll() {
      if (this.mappedVitals?.length) {
        this.vitalsExpandAll = !this.vitalsExpandAll;
        for (const item of this.mappedVitals) {
          this.$set(item, '_showDetails', this.vitalsExpandAll);
        }
      }
    },
    mapData(arrayToMap) {
      return arrayToMap.map(el => ({
        label: el.qualifier_descr,
        value: el.quailfier_value,
      }));
    },
    mapComplaintData(chiefComplaints, otherComplaints) {
      var arr = chiefComplaints.map(el => ({
        history_type: el.history_type,
        long_descr: el.long_descr,
        duration_time_units: this.combine(el.duration, el.time_units, ' '),
        comment: el.comment,
      }));
      arr = arr.concat(
        otherComplaints.map(el => ({
          history_type: el.history_type,
          long_descr: el.long_descr,
          duration_time_units: this.combine(el.duration, el.time_units, ' '),
          comment: el.comment,
        }))
      );
      return arr;
    },
    validateAndParse(date1, time1) {
      var dateVal = date1 + 'T' + time1;
      var lowDate = new Date(1901, 1, 1);
      var date = new Date(dateVal);
      if (this.isValidDate(date) && date > lowDate) {
        return this.dateTimeStr(date);
      }
      return '';
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },
    getCrewType(crewNum) {
      return 'Crew' + crewNum;
    },
    combine(first, second, seperator) {
      if (first && second) {
        return first + seperator + second;
      } else if (first) {
        return first;
      } else if (second) {
        return second;
      }
      return null;
    },
    combineBP(first, second, seperator) {
      var result = '';
      if (first) {
        result += first;
      }
      if (second) {
        result += seperator + second;
      }
      return result;
    },
    calculateTemp(temp) {
      if (temp) {
        if (temp > 9999) {
          return temp / 100 + '°';
        } else {
          return temp / 10 + '°';
        }
      }
      return null;
    },
    mapVitalsExpandedData(vital) {
      var arr = [];
      arr.push({ label: 'GCS', value: vital.gcstotal });
      arr.push({ label: 'Eyes', value: vital.gcseyes });
      arr.push({ label: 'Motor', value: vital.gcsmotor });
      arr.push({ label: 'Verbal', value: vital.gcsverbal });
      arr.push({
        label: 'Total',
        value: vital.gcseyes + vital.gcsmotor + vital.gcsverbal,
      });
      arr.push({ label: 'BGL', value: vital.glucose });
      arr.push({
        label: 'Respiratory',
        value:
          vital.resprate + ' ' + (vital.respeffort ? vital.respeffort : ''),
      });
      arr.push({
        label: 'Lung Sounds L,R',
        value: vital.lungsoundsleft + ' ' + vital.lungsoundsright,
      });
      arr.push({ label: 'Consciousness', value: vital.consciousness });
      return arr;
    },
    async setComplaintsData() {
      if (this.complaintsData && this.complaintsData.length > 0) {
        var arrayLength = this.complaintsData.length;
        var otherComplaintsCount = 0;
        var chiefComplaintsCount = 0;
        for (var i = 0; i < arrayLength; i++) {
          if (this.complaintsData[i].history_type === 'Chief Complaint') {
            this.chiefComplaints[chiefComplaintsCount] = this.complaintsData[i];
            chiefComplaintsCount++;
          } else {
            this.otherComplaints[otherComplaintsCount] = this.complaintsData[i];
            otherComplaintsCount++;
          }
        }
      }
    },
    async setAlsStuff() {
      this.treatments = [];
      var existingTriggers = this.alsTriggers;
      var ALSColors = this.alsColors;
      // var existingTriggers = await appService.getALSTriggers();
      // var ALSColors = await appService.getALSColors();
      var hitALSTriggers = [];
      let uniqueALSTriggers = new Set();
      var applicantVisit = this.visit;
      if (
        applicantVisit.epcrJsonData &&
        applicantVisit.epcrJsonData.treatmentDetails
      ) {
        var currentSeq = -1;
        var previousSeq = -1;
        var currentDetail = {};

        for (var jj = 0; jj < this.treatmentDetails.length; jj++) {
          previousSeq = currentSeq;
          currentSeq = this.treatmentDetails[jj].seq;
          if (previousSeq === -1 || previousSeq !== currentSeq) {
            currentDetail = {};
            currentDetail.seq = this.treatmentDetails[jj].seq;
            currentDetail.timeperformed = this.treatmentDetails[
              jj
            ].timeperformed;
            var pta = this.treatmentDetails[jj].pta;
            if (pta === 1) {
              currentDetail.pta = 'Yes';
            } else {
              currentDetail.pta = 'No';
            }
            currentDetail.intervention_text = this.treatmentDetails[
              jj
            ].intervention_text;
            currentDetail.who_text = this.treatmentDetails[jj].who_text;
            currentDetail.authorized_by = this.treatmentDetails[
              jj
            ].authorized_by;
            currentDetail.comments = this.treatmentDetails[jj].comments;
            currentDetail.certification_level = this.treatmentDetails[
              jj
            ].certification_level;
            currentDetail.details = [];
            this.treatments.push(currentDetail);
          }
          if (this.treatmentDetails[jj].quailfier_value) {
            currentDetail.details.push(this.treatmentDetails[jj]);
          }
          for (var jjj = 0; jjj < existingTriggers.length; jjj++) {
            if (this.treatmentDetails[jj].intervention_text === '*') {
              this.addTrigger(
                uniqueALSTriggers,
                hitALSTriggers,
                existingTriggers[jjj],
                this.treatmentDetails[jj]
              );
            } else {
              if (existingTriggers[jjj].treatment === '*') {
                if (existingTriggers[jjj].qualifier === '*') {
                  this.addTrigger(
                    uniqueALSTriggers,
                    hitALSTriggers,
                    existingTriggers[jjj],
                    this.treatmentDetails[jj]
                  );
                } else {
                  if (
                    this.treatmentDetails[jj].qualifier_descr ===
                    existingTriggers[jjj].qualifier
                  ) {
                    if (existingTriggers[jjj].value === '*') {
                      this.addTrigger(
                        uniqueALSTriggers,
                        hitALSTriggers,
                        existingTriggers[jjj],
                        this.treatmentDetails[jj]
                      );
                    } else {
                      if (
                        this.treatmentDetails[jj].quailfier_value ===
                        existingTriggers[jjj].value
                      ) {
                        this.addTrigger(
                          uniqueALSTriggers,
                          hitALSTriggers,
                          existingTriggers[jjj],
                          this.treatmentDetails[jj]
                        );
                      }
                    }
                  }
                }
              } else {
                if (
                  this.treatmentDetails[jj].intervention_text.substring(
                    0,
                    30
                  ) === existingTriggers[jjj].treatment.substring(0, 30)
                ) {
                  if (existingTriggers[jjj].qualifier === '*') {
                    this.addTrigger(
                      uniqueALSTriggers,
                      hitALSTriggers,
                      existingTriggers[jjj],
                      this.treatmentDetails[jj]
                    );
                  } else {
                    if (
                      this.treatmentDetails[jj].qualifier_descr ===
                      existingTriggers[jjj].qualifier
                    ) {
                      if (existingTriggers[jjj].value === '*') {
                        this.addTrigger(
                          uniqueALSTriggers,
                          hitALSTriggers,
                          existingTriggers[jjj],
                          this.treatmentDetails[jj]
                        );
                      } else {
                        if (
                          this.treatmentDetails[jj].quailfier_value ===
                          existingTriggers[jjj].value
                        ) {
                          this.addTrigger(
                            uniqueALSTriggers,
                            hitALSTriggers,
                            existingTriggers[jjj],
                            this.treatmentDetails[jj]
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        var als1Trigger = false;
        var als2Trigger = false;
        var sctTrigger = false;
        var tooltip = '<div class="wordwrap"><ul>';
        for (var aa = 0; aa < hitALSTriggers.length; aa++) {
          tooltip += `<li class="rowxx row__compact"><div>Trigger: ${hitALSTriggers[aa].trigger.treatment}-${hitALSTriggers[aa].trigger.qualifier}-${hitALSTriggers[aa].trigger.value}</div></li>`;
          tooltip += `<li class="rowxx row__compact"><div>Treatment: ${hitALSTriggers[aa].treatment.intervention_text}</div></li>`;
          if (hitALSTriggers[aa].trigger.callType === 1) {
            als1Trigger = true;
          }
          if (hitALSTriggers[aa].trigger.callType === 2) {
            als2Trigger = true;
          }
          if (hitALSTriggers[aa].trigger.callType === 3) {
            sctTrigger = true;
          }
        }

        tooltip += '</ul></div>';
        this.alsToolTip = tooltip;
        if (sctTrigger) {
          this.alsHighestTrigger =
            'SCT, Level 3 treatment(s) have been detected - Please review';
          this.alsColor = `background-color: ${ALSColors.sctColor}`;
        } else if (als2Trigger) {
          this.alsHighestTrigger =
            'ALS, Level 2 treatment(s) have been detected - Please review';
          this.alsColor = `background-color: ${ALSColors.alS2Color}`;
        } else if (als1Trigger) {
          this.alsHighestTrigger =
            'ALS, Level 1 treatment(s) have been detected - Please review';
          this.alsColor = `background-color: ${ALSColors.alS1Color}`;
        }
      }
      console.log(
        'setAlsStuff.end',
        this.alsHighestTrigger,
        this.alsToolTip,
        this.alsColor
      );
    },
    addTrigger(uniqueALSTriggers, hitALSTriggers, trigger, treatmentDetails) {
      if (!uniqueALSTriggers.has(trigger.id)) {
        uniqueALSTriggers.add(trigger.id);
        hitALSTriggers.push({ trigger: trigger, treatment: treatmentDetails });
      }
    },

    async setTreatmentsExpandAllWithSetting() {
      var profile = this.profiles[0];
      if (profile && profile.expandOptions) {
        //treatment
        var tOption = profile.expandOptions.filter(f => f.name === 'Treatment');
        if (tOption && tOption.length > 0 && tOption[0] && this.treatments) {
          console.log('treatments', this.treatments);
          if (tOption[0]?.expandAll === 'true') {
            for (const item of this.treatments) {
              this.$set(item, '_showDetails', true);
            }
          } else if (tOption[0]?.expandAll === 'false') {
            for (const item of this.treatments) {
              this.$set(item, '_showDetails', false);
            }
          }
        }
      }
    },

    async setVitalsExpandAllWithSetting() {
      var profile = this.profiles[0];
      if (profile && profile.expandOptions) {
        var vOption = profile.expandOptions.filter(f => f.name === 'Vitals');
        console.log('vital option', vOption);
        if (vOption && vOption.length > 0 && vOption[0] && this.mappedVitals) {
          if (vOption[0]?.expandAll === 'true') {
            for (const item of this.mappedVitals) {
              this.$set(item, '_showDetails', true);
            }
          } else if (vOption[0]?.expandAll === 'false') {
            for (const item of this.mappedVitals) {
              this.$set(item, '_showDetails', false);
            }
          }
        }
      }
    },
  },
  watch: {
    // TODO: refactor this into the widget component,
    // when ALL widgets actually use that component, to be DRY
    loading: {
      immediate: true,
      handler(current, previous) {
        // make sure the DOM is updated before dispatching this action
        // because we need the widget to have its full dimensions after
        // loading the data and rendering them...
        this.$nextTick(function() {
          if (current !== previous) {
            this.loadedCallback(!current);
          }
        });
      },
    },
    //somehow, after each time the mappedVitals got updated, it will loose the _showDetails property, and the
    //expand all won't work anymore, so i have to add a watch
    mappedVitals: {
      immediate: true,
      handler() {
        this.setVitalsExpandAllWithSetting();
      },
    },
  },
  async created() {
    if (!this.alsConfigRetrieved && !this.alsConfigLoading) {
      await this[GET_ALS_CONFIG]();
    }
    this.setComplaintsData();
    this.setAlsStuff();

    //Set treatment expand all based on setting.
    this.setTreatmentsExpandAllWithSetting();
  },
  beforeDestroy() {
    this.loadedCallback(false);
  },
};
