import { signatureData } from './computed/signatureData';
export default {
  props: {
    signatureData: Array,
  },
  computed: {
    signatures: {
      get: function() {
        return signatureData.call(this);
      },
    },
  },
};
