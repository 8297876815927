import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronRight,
  faChevronDown,
} from '@fortawesome/pro-light-svg-icons';

library.add(faChevronRight);
library.add(faChevronDown);

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggleSlot() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
